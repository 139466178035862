import Checkbox from 'bloko/blocks/checkbox';
import FormItem from 'bloko/blocks/form/FormItem';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import AdvancedFilter from 'lux/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    fieldTitle: 'searchvacancy.clusters.company',
};

const Company: TranslatedComponent = ({ trls }) => {
    const company = useSelector(({ advancedSearch }) => advancedSearch.company);
    if (!company) {
        return null;
    }

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <FormItem baseline>
                <Checkbox
                    name="employer_id"
                    value={company.id}
                    defaultChecked={true}
                    data-qa="vacancysearch__similar-resume-item"
                >
                    {company.name}
                </Checkbox>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(Company);
