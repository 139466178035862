import Column from 'bloko/blocks/column';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import PageLayout, { SideBarLayout } from 'lux/components/PageLayout';
import translation from 'lux/components/translation';

import AdvancedVacancySearchForm from 'lux/pages/AdvancedVacancySearch/components/AdvancedVacancySearchForm';
import RightBanners from 'lux/pages/AdvancedVacancySearch/components/RightBanners';

const TrlKeys = {
    title: 'vacancySearch.title',
};

const AdvancedVacancySearch: TranslatedComponent = ({ trls }) => {
    const title = trls[TrlKeys.title];

    return (
        <PageLayout title={title} layout={SideBarLayout} sidebar={RightBanners}>
            <Column xs="4" s="8" m="8" l="11">
                <H1>{title}</H1>
                <VSpacing base={6} />
            </Column>
            <AdvancedVacancySearchForm />
        </PageLayout>
    );
};

export default translation(AdvancedVacancySearch);
