import { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';

import { shouldRenderNoMagicHiddenInput } from 'lux/components/AdvancedSearchFilters/utils';

const NoMagicHiddenInput: FC = () => {
    const noMagic = useSelector(({ router }) => router.location.query?.no_magic);

    // Add NoMagic hidden input only if there is no_magic query param in url
    if (!shouldRenderNoMagicHiddenInput(noMagic)) {
        return null;
    }

    return <input type="hidden" name="no_magic" value="true" />;
};

export default NoMagicHiddenInput;
