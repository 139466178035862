import { useState } from 'react';

import { FormItem } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

import AdvancedFilter from 'lux/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    placeholder: 'novaFilters.excludedText',
    fieldTitle: 'clusters.excluded_text',
};

const VacancySearchText: TranslatedComponent = ({ trls }) => {
    const queryText = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.ExcludedText]
    );
    const [text, setText] = useState(queryText || '');

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <FormItem>
                <InputText
                    name={CriteriaKey.ExcludedText}
                    value={text}
                    onChange={setText}
                    placeholder={trls[TrlKeys.placeholder]}
                    autoComplete="off"
                    data-qa="vacancysearch__keywords-excluded-input"
                />
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(VacancySearchText);
