import { useContext } from 'react';

import { FormItem } from 'bloko/blocks/form';
import Select, { Option } from 'bloko/blocks/select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AutoInviteContext from 'lux/components/AutoInviteModal/common/AutoInviteContext';
import translation from 'lux/components/translation';
import { RelocationOption } from 'lux/models/resumeSearchCriteria';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

export const DEFAULT_RELOCATION = RelocationOption.LivingOrRelocation;

const TrlKeys = {
    singular: {
        [RelocationOption.Living]: 'employer.resumesearch.relocation.singular.living',
        [RelocationOption.LivingButRelocation]: 'employer.resumesearch.relocation.singular.living_but_relocation',
        [RelocationOption.LivingOrRelocation]: 'employer.resumesearch.relocation.singular.living_or_relocation',
        [RelocationOption.Relocation]: 'employer.resumesearch.relocation.singular.relocation',
    },
    plural: {
        [RelocationOption.Living]: 'employer.resumesearch.relocation.plural.living',
        [RelocationOption.LivingButRelocation]: 'employer.resumesearch.relocation.plural.living_but_relocation',
        [RelocationOption.LivingOrRelocation]: 'employer.resumesearch.relocation.plural.living_or_relocation',
        [RelocationOption.Relocation]: 'employer.resumesearch.relocation.plural.relocation',
    },
};

interface RelocationProps {
    areas: string[];
    autoInvite?: boolean;
}

const Relocation: TranslatedComponent<RelocationProps> = ({ trls, areas, autoInvite }) => {
    const filterOptions = useSelector((state) => state.resumeSearchDictionaries[CriteriaKey.Relocation]);
    const value = useSelector((state) =>
        autoInvite
            ? state.autoInvite?.filters.relocation
            : state.router.location.query?.relocation || DEFAULT_RELOCATION
    );
    const hasManyAreas = areas.length > 1;
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    return (
        <FormItem>
            <Select name="relocation" defaultValue={value} onChange={autoInviteHandlerFormChange || undefined}>
                {filterOptions.map((item) => (
                    <Option key={item} value={item} data-qa={`vacancy-responses-filter-relocation-${item}`}>
                        {trls[TrlKeys[hasManyAreas ? 'plural' : 'singular'][item]]}
                    </Option>
                ))}
            </Select>
        </FormItem>
    );
};

export default translation(Relocation);
