import { DataProviderResult, CellText } from '@hh.ru/magritte-ui';

import { Autosuggest } from 'lux/utils/suggest/buildDataProvider';

export interface FormSuggestItem extends Autosuggest {
    text: string;
}

const formatter = <T extends FormSuggestItem>(items: T[] | null): DataProviderResult<T> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item.text,
                componentProps: {
                    children: <CellText>{item.text}</CellText>,
                },
            },
        ],
    }));
};

export default formatter;
