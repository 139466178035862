import { useState, RefObject } from 'react';

import Button from 'bloko/blocks/button';
import { FormItem } from 'bloko/blocks/form';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import AdvancedFilter from 'lux/components/AdvancedSearchFilters/AdvancedFilter';

enum StatFormat {
    Page = 'page',
    Csv = 'csv',
}

const TrlKeys = {
    title: 'supernova.navi.jobStatistics',
    makeStat: 'vacancySearch.stat.make',
    makeStatCSV: 'vacancySearch.stat.make.csv',
};

const BOStatistics: TranslatedComponent<{ formRef: RefObject<HTMLFormElement> }> = ({ trls, formRef }) => {
    const [statFormat, setStatFormat] = useState<StatFormat | null>(null);

    const onSubmit = (statFormatSelected: StatFormat) => {
        setStatFormat(statFormatSelected);
        setTimeout(() => {
            if (!formRef.current) {
                return;
            }
            formRef.current.submit();
            setStatFormat(null);
        }, 0);
    };

    return (
        <AdvancedFilter legend={trls[TrlKeys.title]}>
            <FormItem baseline>
                <input hidden disabled={statFormat !== StatFormat.Page} name="redirectToStat" value="true" />
                <Button onClick={() => onSubmit(StatFormat.Page)}>{trls[TrlKeys.makeStat]}</Button>
            </FormItem>
            <FormItem baseline>
                <input hidden disabled={statFormat !== StatFormat.Csv} name="redirectToStatCsv" value="true" />
                <Button onClick={() => onSubmit(StatFormat.Csv)}>{trls[TrlKeys.makeStatCSV]}</Button>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(BOStatistics);
