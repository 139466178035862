import { FC, ReactNode, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';
import FormItem from 'bloko/blocks/form/FormItem';
import FormLegend from 'bloko/blocks/form/FormLegend';
import VSpacing from 'bloko/blocks/vSpacing';

interface AdvancedFilterProps {
    legend: ReactNode;
}

const AdvancedFilter: FC<AdvancedFilterProps & PropsWithChildren> = ({ legend, children }) => {
    return (
        <>
            <div data-qa="advanced-filter">
                <VSpacing base={2} />
                <Column xs="4" s="8" m="3" l="4">
                    <FormItem>
                        <FormLegend>{legend}</FormLegend>
                    </FormItem>
                </Column>
                <Column xs="4" s="8" m="5" l="7">
                    {children}
                </Column>
            </div>
            <VSpacing base={6} />
        </>
    );
};

export default AdvancedFilter;
