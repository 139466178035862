import Checkbox from 'bloko/blocks/checkbox';
import FormItem from 'bloko/blocks/form/FormItem';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import styles from './accept-temporary.less';

const TrlKeys = {
    fieldTitle: 'novafilters.accept_temporary.true',
};

const AcceptTemporary: TranslatedComponent<{ defaultChecked: boolean }> = ({ trls, defaultChecked }) => {
    return (
        <>
            <VSpacing base={1} />
            <div className={styles.divider} />
            <VSpacing base={1} />
            <FormItem baseline>
                <Checkbox
                    name="accept_temporary"
                    value="true"
                    defaultChecked={defaultChecked}
                    data-qa={`advanced-search__accept_temporary-item_true`}
                >
                    {trls[TrlKeys.fieldTitle]}
                </Checkbox>
            </FormItem>
        </>
    );
};

export default translation(AcceptTemporary);
