import { useCallback, useMemo, ReactNode } from 'react';

import InputText from 'bloko/blocks/inputText';
import { SuggestLayer } from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SmartSuggest from 'lux/components/SupernovaSearch/SmartSuggest';
import SmartSuggestItems from 'lux/components/SupernovaSearch/SmartSuggest/SmartSuggestItems';
import useSendSuggestAnalytics from 'lux/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import translation from 'lux/components/translation';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';

const TrlKeys = {
    apply: 'Apply',
};

const WILDCARD = '%QUERY%';
const REMOTE = `/vacancysuggest/?q=${WILDCARD}`;

interface VacancySearchSuggestProps {
    suggestValue: DataProviderItem;
    setSuggestValue: (suggestValue: DataProviderItem) => void;
}

const VacancySearchSuggest: TranslatedComponent<VacancySearchSuggestProps> = ({
    trls,
    suggestValue,
    setSuggestValue,
}) => {
    const { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics } = useSendSuggestAnalytics();
    const isXs = useBreakpoint() === Breakpoint.XS;
    const remoteDataProvider = useMemo(() => createRemoteDataProvider(REMOTE, WILDCARD), []);

    const dataProvider = useCallback(
        (query: string) => {
            return remoteDataProvider(query);
        },
        [remoteDataProvider]
    );

    const onSmartSuggestChange = (item: DataProviderItem) => {
        if (item) {
            sendSuggestItemClickAnalytics(item);
        }
        setSuggestValue(item);
    };

    return (
        <SmartSuggest
            autoHighlightFirstSuggest={false}
            limit={12}
            layer={SuggestLayer.AboveOverlayContent}
            dataProvider={dataProvider}
            inputValue={suggestValue?.text}
            value={suggestValue}
            onChange={onSmartSuggestChange}
            selectOnBlur={false}
            searchOnFocus={true}
            suggestStartInputLength={2}
            renderItems={(items, renderItem: (item: DataProviderItem, index: number) => ReactNode) => {
                return <SmartSuggestItems items={items} renderItem={renderItem} />;
            }}
            onSubmit={(value: string) => setSuggestValue({ text: value })}
            submitButtonTitle={trls[TrlKeys.apply]}
            onMobileSuggestShown={sendSuggestShownAnalytics}
        >
            <InputText
                name={CriteriaKey.Text}
                autoComplete="off"
                data-qa="vacancysearch__keywords-input"
                showClearButton={!isXs}
            />
        </SmartSuggest>
    );
};

export default translation(VacancySearchSuggest);
