import Button, { ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    submit: 'button.search',
};

const SubmitButton: TranslatedComponent = ({ trls }) => (
    <div className="search-submit-wrapper">
        <Column xs="0" s="3" m="3" l="4" />
        <Column xs="4" s="2" m="2" l="2">
            <Button
                kind={ButtonKind.Primary}
                type={ButtonType.Submit}
                scale={ButtonScale.Large}
                data-qa="advanced-search-submit-button"
                stretched
            >
                {trls[TrlKeys.submit]}
            </Button>
        </Column>
    </div>
);

export default translation(SubmitButton);
