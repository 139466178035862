import { useState, FC } from 'react';
import lightFormat from 'date-fns/lightFormat';

import SingleCalendarPicker from 'bloko/blocks/calendar/SingleCalendarPicker';
import { toDate, toISO } from 'bloko/blocks/calendar/datesHelper';
import ClickDown, { DownLayer, DownPlacement } from 'bloko/blocks/drop/Down/Click';
import Link, { LinkAppearance } from 'bloko/blocks/link';

import HumanDate from 'lux/components/HumanDate';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

const DATE_FORMAT_STRING = 'dd.MM.yyyy';

interface DateSelectorProps {
    course: typeof CriteriaKey.DateFrom | typeof CriteriaKey.DateTo;
}

const DateSelector: FC<DateSelectorProps> = ({ course }) => {
    const initialValue = useSelector((state) => {
        const date = state.router.location.query?.[course];
        if (!date) {
            return null;
        }
        const formattedDate = `${date.slice(6, 10)}-${date.slice(3, 5)}-${date.slice(0, 2)}`;
        const dateValue = new Date(formattedDate);
        if (isNaN(dateValue.valueOf())) {
            return null;
        }
        return dateValue;
    });
    const [date, setDate] = useState(toISO(initialValue || new Date()));

    return (
        <ClickDown
            layer={DownLayer.AboveOverlayContent}
            render={(close) => (
                <SingleCalendarPicker
                    initialDate={date}
                    highlighted={date}
                    onDateClick={(newDate) => {
                        newDate && setDate(newDate);
                        close?.();
                    }}
                />
            )}
            placement={DownPlacement.BottomEnd}
        >
            <Link appearance={LinkAppearance.Pseudo}>
                <HumanDate date={date} />
                <input
                    type="hidden"
                    name={course}
                    suppressHydrationWarning
                    value={lightFormat(toDate(date), DATE_FORMAT_STRING)}
                />
            </Link>
        </ClickDown>
    );
};

export default DateSelector;
