import { ReactNode, FC } from 'react';

import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';

type RenderLineIcon = (color: string, isMagritte?: boolean) => ReactNode;

export const renderLineIcon: RenderLineIcon = (color, isMagritte) => {
    if (isMagritte) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={9} y={4} width={6} height={16} rx={3} fill={color} />
            </svg>
        );
    }

    return (
        <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="8.5" r="4" fill={color} />
            <rect x="2" y="0.5" width="4" height="3" fill={color} />
            <rect x="2" y="13.5" width="4" height="3" fill={color} />
        </svg>
    );
};

interface MetroLineProps {
    name: string;
    color: string;
}

const MetroLine: FC<MetroLineProps> = ({ name, color }) => (
    <span>
        {color && <span className="novafilters-metro-icon novafilters-metro-icon_line">{renderLineIcon(color)}</span>}
        <FilterTitle title={name} truncated />
    </span>
);

export default MetroLine;
