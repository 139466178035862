import { FC, PropsWithChildren } from 'react';

import { useSelector } from 'lux/modules/useSelector';

import NoMagicHiddenInput from 'lux/components/AdvancedSearchFilters/NoMagicHiddenInput';

const VacancyHiddenInputs: FC<PropsWithChildren> = () => {
    const recommendedByUid = useSelector(({ router }) => router.location.query?.recommended_by_uid);
    return (
        <>
            {recommendedByUid && <input type="hidden" name="recommended_by_uid" value="true" />}
            <NoMagicHiddenInput />
            <input type="hidden" name="L_save_area" value="true" />
        </>
    );
};

export default VacancyHiddenInputs;
