import { FC } from 'react';

import { renderLineIcon } from 'lux/components/NovaFilters/vacancies/Metro/MetroLine';

import styles from './metro-line.less';

interface MetroLineProps {
    color?: string;
}

const MetroLine: FC<MetroLineProps> = ({ color }) => {
    if (!color) {
        return null;
    }

    return <div className={styles.metroLine}>{renderLineIcon(color)}</div>;
};

export default MetroLine;
